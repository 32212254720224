/**
 * We need to map the v2 user with their counterpart on v1.
 * Therefore on every login we want to ensure the user is mapped
 */

export default defineNuxtPlugin(() => {
	const supabase = useSupabaseClient();
	const { $api } = useNuxtApp();

	supabase.auth.onAuthStateChange(async (event, s) => {
		if (event === 'INITIAL_SESSION' && s) {
			await $api.GET('/map');
		}
	});
});
