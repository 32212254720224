import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin({
	name: 'sentry-client',
	setup: (nuxtApp) => {
		const router = useRouter();
		const { public: { sentry } } = useRuntimeConfig();

		if (!sentry.dsn) {
			return;
		}

		Sentry.init({
			app: nuxtApp.vueApp,
			dsn: sentry.dsn,
			environment: sentry.environment,
			integrations: [
				Sentry.browserTracingIntegration({
					router,
				}),
				Sentry.replayIntegration(),
			],

			// Configure this whole part as you need it!

			tracesSampleRate: 0.5, // Change in prod

			// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ['localhost', 'local.clickfire.io', 'v2.clickfire.io'],

			replaysSessionSampleRate: 1.0, // Change in prod
			replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
			normalizeDepth: 4,
		});

		const user = useSupabaseUser();

		watch(user, (_user) => {
			Sentry.setUser(_user);
		}, { immediate: true });
	},
});
