export default defineNuxtRouteMiddleware((to) => {
	const user = useSupabaseUser();

	if (!user.value && to.meta.auth === true) {
		if (to.path.includes('/admin')) {
			return navigateTo('/login?redirectTo=/admin');
		}

		return navigateTo({ name: 'login', query: to.query });
	}
});
