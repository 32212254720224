import { DateTime } from 'luxon';
import type { CamelToSnakeCaseKeys, SnakeToCamelCaseKeys } from '~/models/Types';

export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue => {
	return value !== null && value !== undefined;
};

export const insertIf = <T>(condition: boolean | string | undefined, ...elements: T[]) => {
	return condition ? elements : [];
};

export const getDateFromDateString = (dateString: string) => {
	const match = dateString.match(/\d+/);

	if (!match) {
		return null;
	}

	const seconds = parseInt(match[0]) / 1000;

	return DateTime.fromSeconds(seconds);
};

export const snakeToCamel = (input: string): string => {
	return input.toLowerCase()
		.replace(/(_\w)/g, m => m.toUpperCase()
			.substring(1));
};

export const camelToSnake = (s: string): string =>
	s.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`);

export const convertSnakeToCamel = <T extends Record<string, unknown>>(obj: T): SnakeToCamelCaseKeys<T> => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: any = {};

	for (const key in obj) {
		result[snakeToCamel(key)] = obj[key];
	}

	return result as SnakeToCamelCaseKeys<T>;
};

export const convertCamelToSnake = <T extends Record<string, unknown>>(obj: T): CamelToSnakeCaseKeys<T> => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: any = {};

	for (const key in obj) {
		result[camelToSnake(key)] = obj[key];
	}

	return result as CamelToSnakeCaseKeys<T>;
};

export const withoutEmptyProperties = <T extends object>(input: T) => {
	return Object.entries(input).reduce((copy, [key, value]) => {
		if (!value) {
			return copy;
		}

		if (typeof value === 'object') {
			if (Object.keys(value).length < 1) {
				return copy;
			}

			if (Object.values(value).every(v => !v)) {
				return copy;
			}
		}

		return {
			...copy,
			[key]: value,
		};
	}, {} as T);
};

export const sortAlphabeticallyBy = <T extends object>(key: keyof T, ascending = false) => {
	return (a: T, b: T): number => {
		const stringA = String(a[key]).toLocaleLowerCase();
		const stringB = String(b[key]).toLocaleLowerCase();

		if (stringA < stringB) return ascending ? 1 : -1;
		if (stringA > stringB) return ascending ? -1 : 1;

		return 0;
	};
};

export const isTrueValue = (input: unknown) => {
	if (typeof input === 'boolean') {
		return input;
	}

	if (typeof input === 'string') {
		return input.toLowerCase() === 'true';
	}

	return false;
};

export const ensureArray = <T>(input: T[] | undefined): T[] => {
	return input ?? [];
};
