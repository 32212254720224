import countries from 'i18n-iso-countries';
import enCountries from 'i18n-iso-countries/langs/en.json';
import deCountries from 'i18n-iso-countries/langs/de.json';
import languages from '@cospired/i18n-iso-languages';
import enLanguages from '@cospired/i18n-iso-languages/langs/en.json';
import deLanguages from '@cospired/i18n-iso-languages/langs/de.json';
import type { Locale } from '~/models/Locale';

type CountriesData = {
	locale: string;
	countries: Record<string, string | string[]>;
};

type LanguagesData = {
	locale: string;
	languages: Record<string, string>;
};

export default defineNuxtPlugin(async () => {
	const localeDataMap: Record<Locale, { languages: LanguagesData; countries: CountriesData; }> = {
		de: {
			languages: deLanguages,
			countries: deCountries,
		},
		en: {
			languages: enLanguages,
			countries: enCountries,
		},
	};

	Object.values(localeDataMap)
		.forEach(({ countries: countriesData, languages: languagesData }) => {
			countries.registerLocale(countriesData);
			languages.registerLocale(languagesData);
		});

	return {
		provide: {
			countries,
			languages,
		},
	};
});
