import { default as indexQHLGOCDfydMeta } from "/opt/build/repo/pages/account/index.vue?macro=true";
import { default as passwordzzPHiL1BF4Meta } from "/opt/build/repo/pages/account/password.vue?macro=true";
import { default as paymentXxNIGCwdU2Meta } from "/opt/build/repo/pages/account/payment.vue?macro=true";
import { default as payoutEt4cdOXOeiMeta } from "/opt/build/repo/pages/account/payout.vue?macro=true";
import { default as social_45mediats5TYjs07GMeta } from "/opt/build/repo/pages/account/social-media.vue?macro=true";
import { default as indexcdPbvi2lFPMeta } from "/opt/build/repo/pages/admin/[merchantId]/campaigns/[campaignId]/index.vue?macro=true";
import { default as submissions6T7CSLHvVwMeta } from "/opt/build/repo/pages/admin/[merchantId]/campaigns/[campaignId]/submissions.vue?macro=true";
import { default as indexVeTPCnmOmtMeta } from "/opt/build/repo/pages/admin/[merchantId]/campaigns/index.vue?macro=true";
import { default as new7u3gIZLS4aMeta } from "/opt/build/repo/pages/admin/[merchantId]/campaigns/new.vue?macro=true";
import { default as index30he8ukydVMeta } from "/opt/build/repo/pages/admin/[merchantId]/index.vue?macro=true";
import { default as indexKyTATD8DMLMeta } from "/opt/build/repo/pages/admin/[merchantId]/payouts/index.vue?macro=true";
import { default as _91submissionId_93xgR0rUPyW3Meta } from "/opt/build/repo/pages/admin/[merchantId]/submissions/[submissionId].vue?macro=true";
import { default as index7qcPXZQ3fgMeta } from "/opt/build/repo/pages/admin/index.vue?macro=true";
import { default as applynm5obgG8T0Meta } from "/opt/build/repo/pages/campaigns/[merchantId]/[campaignId]/apply.vue?macro=true";
import { default as indexVUINZRTexpMeta } from "/opt/build/repo/pages/campaigns/[merchantId]/[campaignId]/index.vue?macro=true";
import { default as indexaLr7v1TpznMeta } from "/opt/build/repo/pages/campaigns/index.vue?macro=true";
import { default as confirm487kH8ZehdMeta } from "/opt/build/repo/pages/confirm.vue?macro=true";
import { default as dashboardB0S6bZ6pqpMeta } from "/opt/build/repo/pages/dashboard.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as logoutlLSqGnk5PzMeta } from "/opt/build/repo/pages/logout.vue?macro=true";
import { default as new_45passwordFYkUQ9WOpMMeta } from "/opt/build/repo/pages/new-password.vue?macro=true";
import { default as indexwG5fFzYx1MMeta } from "/opt/build/repo/pages/payouts/index.vue?macro=true";
import { default as reset_45password4iBG212Ge4Meta } from "/opt/build/repo/pages/reset-password.vue?macro=true";
import { default as confirmQhglElOkIZMeta } from "/opt/build/repo/pages/signup/confirm.vue?macro=true";
import { default as index49X2UrbFTSMeta } from "/opt/build/repo/pages/signup/index.vue?macro=true";
import { default as profileq2bdcGmLxHMeta } from "/opt/build/repo/pages/signup/profile.vue?macro=true";
import { default as _91submissionId_93XRlmn26oJnMeta } from "/opt/build/repo/pages/submissions/[merchantId]/[submissionId].vue?macro=true";
import { default as indexgfrMUT4WhoMeta } from "/opt/build/repo/pages/submissions/index.vue?macro=true";
import { default as v1redirectVmY2pwdMS3Meta } from "/opt/build/repo/pages/v1redirect.vue?macro=true";
import { default as v1signinz198P0JcB1Meta } from "/opt/build/repo/pages/v1signin.vue?macro=true";
import { default as component_45stub5TNewS3v7vMeta } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5TNewS3v7v } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account",
    path: "/account",
    meta: indexQHLGOCDfydMeta || {},
    component: () => import("/opt/build/repo/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-password",
    path: "/account/password",
    meta: passwordzzPHiL1BF4Meta || {},
    component: () => import("/opt/build/repo/pages/account/password.vue").then(m => m.default || m)
  },
  {
    name: "account-payment",
    path: "/account/payment",
    meta: paymentXxNIGCwdU2Meta || {},
    component: () => import("/opt/build/repo/pages/account/payment.vue").then(m => m.default || m)
  },
  {
    name: "account-payout",
    path: "/account/payout",
    meta: payoutEt4cdOXOeiMeta || {},
    component: () => import("/opt/build/repo/pages/account/payout.vue").then(m => m.default || m)
  },
  {
    name: "account-social-media",
    path: "/account/social-media",
    meta: social_45mediats5TYjs07GMeta || {},
    component: () => import("/opt/build/repo/pages/account/social-media.vue").then(m => m.default || m)
  },
  {
    name: "admin-merchantId-campaigns-campaignId",
    path: "/admin/:merchantId()/campaigns/:campaignId()",
    meta: indexcdPbvi2lFPMeta || {},
    component: () => import("/opt/build/repo/pages/admin/[merchantId]/campaigns/[campaignId]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-merchantId-campaigns-campaignId-submissions",
    path: "/admin/:merchantId()/campaigns/:campaignId()/submissions",
    meta: submissions6T7CSLHvVwMeta || {},
    component: () => import("/opt/build/repo/pages/admin/[merchantId]/campaigns/[campaignId]/submissions.vue").then(m => m.default || m)
  },
  {
    name: "admin-merchantId-campaigns",
    path: "/admin/:merchantId()/campaigns",
    meta: indexVeTPCnmOmtMeta || {},
    component: () => import("/opt/build/repo/pages/admin/[merchantId]/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-merchantId-campaigns-new",
    path: "/admin/:merchantId()/campaigns/new",
    meta: new7u3gIZLS4aMeta || {},
    component: () => import("/opt/build/repo/pages/admin/[merchantId]/campaigns/new.vue").then(m => m.default || m)
  },
  {
    name: "AdminMerchantDashboard",
    path: "/admin/:merchantId()",
    meta: index30he8ukydVMeta || {},
    component: () => import("/opt/build/repo/pages/admin/[merchantId]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-merchantId-payouts",
    path: "/admin/:merchantId()/payouts",
    meta: indexKyTATD8DMLMeta || {},
    component: () => import("/opt/build/repo/pages/admin/[merchantId]/payouts/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-merchantId-submissions-submissionId",
    path: "/admin/:merchantId()/submissions/:submissionId()",
    meta: _91submissionId_93xgR0rUPyW3Meta || {},
    component: () => import("/opt/build/repo/pages/admin/[merchantId]/submissions/[submissionId].vue").then(m => m.default || m)
  },
  {
    name: "AdminMerchantSelection",
    path: "/admin",
    meta: index7qcPXZQ3fgMeta || {},
    component: () => import("/opt/build/repo/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "ApplyForCampaign",
    path: "/campaigns/:merchantId()/:campaignId()/apply",
    meta: applynm5obgG8T0Meta || {},
    component: () => import("/opt/build/repo/pages/campaigns/[merchantId]/[campaignId]/apply.vue").then(m => m.default || m)
  },
  {
    name: "Campaign",
    path: "/campaigns/:merchantId()/:campaignId()",
    meta: indexVUINZRTexpMeta || {},
    component: () => import("/opt/build/repo/pages/campaigns/[merchantId]/[campaignId]/index.vue").then(m => m.default || m)
  },
  {
    name: "MerchantCampaigns",
    path: "/campaigns",
    meta: indexaLr7v1TpznMeta || {},
    component: () => import("/opt/build/repo/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: "confirm",
    path: "/confirm",
    component: () => import("/opt/build/repo/pages/confirm.vue").then(m => m.default || m)
  },
  {
    name: "MerchantDashboard",
    path: "/dashboard",
    meta: dashboardB0S6bZ6pqpMeta || {},
    component: () => import("/opt/build/repo/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginBXD9sq3T3sMeta || {},
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/opt/build/repo/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "new-password",
    path: "/new-password",
    meta: new_45passwordFYkUQ9WOpMMeta || {},
    component: () => import("/opt/build/repo/pages/new-password.vue").then(m => m.default || m)
  },
  {
    name: "MerchantPayouts",
    path: "/payouts",
    meta: indexwG5fFzYx1MMeta || {},
    component: () => import("/opt/build/repo/pages/payouts/index.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password4iBG212Ge4Meta || {},
    component: () => import("/opt/build/repo/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "signup-confirm",
    path: "/signup/confirm",
    meta: confirmQhglElOkIZMeta || {},
    component: () => import("/opt/build/repo/pages/signup/confirm.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    meta: index49X2UrbFTSMeta || {},
    component: () => import("/opt/build/repo/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: "signup-profile",
    path: "/signup/profile",
    meta: profileq2bdcGmLxHMeta || {},
    component: () => import("/opt/build/repo/pages/signup/profile.vue").then(m => m.default || m)
  },
  {
    name: "Submission",
    path: "/submissions/:merchantId()/:submissionId()",
    meta: _91submissionId_93XRlmn26oJnMeta || {},
    component: () => import("/opt/build/repo/pages/submissions/[merchantId]/[submissionId].vue").then(m => m.default || m)
  },
  {
    name: "MerchantSubmissions",
    path: "/submissions",
    meta: indexgfrMUT4WhoMeta || {},
    component: () => import("/opt/build/repo/pages/submissions/index.vue").then(m => m.default || m)
  },
  {
    name: "v1redirect",
    path: "/v1redirect",
    meta: v1redirectVmY2pwdMS3Meta || {},
    component: () => import("/opt/build/repo/pages/v1redirect.vue").then(m => m.default || m)
  },
  {
    name: "v1signin",
    path: "/v1signin",
    component: () => import("/opt/build/repo/pages/v1signin.vue").then(m => m.default || m)
  },
  {
    name: component_45stub5TNewS3v7vMeta?.name,
    path: "/admin/login",
    component: component_45stub5TNewS3v7v
  }
]