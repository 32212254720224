import revive_payload_client_VMqsyjt7Q5 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_HT5DZbKlhy from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Z65FbANjnl from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_gAthkTXrLu from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+supabase@1.2.2_rollup@4.18.1/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_AQTYwWiIXi from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_jHzr3p7vIR from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_rWom5NuNAJ from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_FFppHsShiV from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_sNE4fK3sr0 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_iBPiJ3KtMb from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_rollup@4.18.1_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_UqfJT89Q9l from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_rollup@4.18.1_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideovers_vH1Jn4eZZ1 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_rollup@4.18.1_vite@5.3.4_vue@3.4.38/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_d5LlatXxva from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_rollup@4.18.1_vite@5.3.4_vue@3.4.38/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_Hh97tWEu2G from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_rollup@4.18.1_vite@5.3.4_vue@3.4.38/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_Gdjb9PRXJS from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_rollup@4.18.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_ecYaC6MCzg from "/opt/build/repo/node_modules/.pnpm/@nuxt+icon@1.4.5_rollup@4.18.1_vite@5.3.4_vue@3.4.38/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import auth_redirect_glWSTBaQeE from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+supabase@1.2.2_rollup@4.18.1/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.mjs";
import api_GFfDXud5Cr from "/opt/build/repo/plugins/api.ts";
import i18n_VfGcjrvSkj from "/opt/build/repo/plugins/i18n.ts";
import sentry_client_shVUlIjFLk from "/opt/build/repo/plugins/sentry.client.ts";
import userApiMapping_client_vfoioqdINo from "/opt/build/repo/plugins/userApiMapping.client.ts";
import userLocaleMapping_client_db1Wddiegg from "/opt/build/repo/plugins/userLocaleMapping.client.ts";
import vue_query_wrmMkNpEpe from "/opt/build/repo/plugins/vue-query.ts";
import zod_q7cfI0sipZ from "/opt/build/repo/plugins/zod.ts";
export default [
  revive_payload_client_VMqsyjt7Q5,
  unhead_HT5DZbKlhy,
  router_Z65FbANjnl,
  supabase_client_gAthkTXrLu,
  payload_client_AQTYwWiIXi,
  navigation_repaint_client_jHzr3p7vIR,
  check_outdated_build_client_rWom5NuNAJ,
  chunk_reload_client_FFppHsShiV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_sNE4fK3sr0,
  switch_locale_path_ssr_iBPiJ3KtMb,
  i18n_UqfJT89Q9l,
  slideovers_vH1Jn4eZZ1,
  modals_d5LlatXxva,
  colors_Hh97tWEu2G,
  plugin_client_Gdjb9PRXJS,
  plugin_ecYaC6MCzg,
  auth_redirect_glWSTBaQeE,
  api_GFfDXud5Cr,
  i18n_VfGcjrvSkj,
  sentry_client_shVUlIjFLk,
  userApiMapping_client_vfoioqdINo,
  userLocaleMapping_client_db1Wddiegg,
  vue_query_wrmMkNpEpe,
  zod_q7cfI0sipZ
]