import { z } from 'zod';
import type { components } from './Api';
import { zStringReq } from '@/utils/zStringReq';

export type UserProfile = components['schemas']['Profile'];

export const userProfileSchema = z.object({
	firstName: zStringReq(),
	lastName: zStringReq(),
});
