export const unwrapResponse = <T>(response: { data?: T; }) => {
	return response.data;
};

export const unwrapResponseOrThrow = <T>(response: { data?: T; }) => {
	if (!response.data) {
		throw new Error('"data" is not defined');
	}

	return response.data;
};
