import { useQuery } from '@tanstack/vue-query';

export const useAdminMerchantsQuery = () => {
	const { $api } = useNuxtApp();

	const query = useQuery({
		queryKey: ['admin-merchants'],
		queryFn: async () => $api.GET('/admin/merchants')
			.then(unwrapResponse)
			.then(data => data?.toSorted(sortAlphabeticallyBy('name'))),
	});

	return query;
};
