import validate from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45v1signin_45global from "/opt/build/repo/middleware/01.v1signin.global.ts";
import _02_45auth_45global from "/opt/build/repo/middleware/02.auth.global.ts";
import _03_45profile_45global from "/opt/build/repo/middleware/03.profile.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_rollup@4.18.1_typescript@5.5.3_vite@5.3.4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45v1signin_45global,
  _02_45auth_45global,
  _03_45profile_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-merchant": () => import("/opt/build/repo/middleware/admin-merchant.ts"),
  admin: () => import("/opt/build/repo/middleware/admin.ts"),
  guest: () => import("/opt/build/repo/middleware/guest.ts")
}