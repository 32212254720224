import { z, ZodIssueCode, type ZodErrorMap, ZodParsedType } from 'zod';
import { match } from 'ts-pattern';

export default defineNuxtPlugin({
	name: 'zod-translations',
	setup() {
		const errorMap: ZodErrorMap = (issue, _ctx) => {
			const message = match(issue)
				.with({ code: ZodIssueCode.invalid_type, received: ZodParsedType.undefined }, () => 'validation.error.required')
				.with({ code: ZodIssueCode.invalid_date }, () => 'validation.error.invalid.date')
				.with({ code: ZodIssueCode.invalid_type, expected: 'integer', received: 'float' }, () => 'validation.error.number.noFloat')
				.with({ code: ZodIssueCode.invalid_string, validation: 'url' }, () => 'validation.error.invalid.url')
				.otherwise(() => _ctx.defaultError);

			return { message };
		};

		z.setErrorMap(errorMap);
	},
});
