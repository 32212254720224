<script setup lang="ts">
const route = useRoute();

useHead({
	titleTemplate: (titleChunk) => {
		return titleChunk ? `${titleChunk} - Clickfire` : 'Clickfire';
	},
});
</script>

<template>
	<div
		:class="{
			'h-full': route.meta.layout === 'blank' || 'default',
		}"
	>
		<NuxtLayout>
			<NuxtPage />
		</NuxtLayout>
		<UNotifications />
		<UModals />
	</div>
</template>
