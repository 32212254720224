export default defineNuxtPlugin((nuxtApp) => {
	const supabase = useSupabaseClient();
	const user = useSupabaseUser();

	nuxtApp.hook('i18n:localeSwitched', ({ newLocale }) => {
		if (!user.value) return;

		supabase.auth.updateUser({
			data: {
				locale: newLocale,
			},
		});
	});

	supabase.auth.onAuthStateChange((state, session) => {
		if (!session || state !== 'INITIAL_SESSION') return;

		supabase.auth.updateUser({
			data: {
				locale: (nuxtApp.$i18n as ReturnType<typeof useI18n>).locale.value,
			},
		});
	});
});
