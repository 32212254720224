<script lang="ts" setup>
const router = useRouter();
const route = useRoute();

const loginRedirect = router.resolve({ name: 'login', query: { redirectTo: route.path } });
</script>

<template>
	<UCard class="text-center max-w-xl mx-auto w-full">
		<UIcon
			name="i-heroicons-lock-closed"
			class="text-6xl text-red-500"
		/>
		<h1 class="text-3xl mb-2">
			{{ $t('error.noAdmin.title') }}
		</h1>
		<p>
			{{ $t('error.noAdmin.subtitle') }}
		</p>
		<template #footer>
			<div class="space-x-4">
				<NuxtLink to="/">
					<UButton
						size="lg"
						color="white"
					>
						{{ $t('button.backToHome') }}
					</UButton>
				</NuxtLink>
				<NuxtLink :to="{ name: 'logout', query: { redirectTo: loginRedirect.fullPath } }">
					<UButton
						size="lg"
						color="white"
					>
						{{ $t('button.logout') }}
					</UButton>
				</NuxtLink>
			</div>
		</template>
	</UCard>
</template>
