import { z } from 'zod';

export type ApiErrorResponse = {
	type: string;
	title: string;
	detail: string;
	status: number;
	traceId: string;
};

export type ApiUnexpectedErrorResponse = {
	type: string;
	title: string;
	status: number;
	errors: Record<string, string[]>;
	traceId: string;
};

export const apiErrorResponseSchema: z.ZodType<ApiErrorResponse> = z.object({
	type: z.string(),
	title: z.string(),
	detail: z.string(),
	status: z.number(),
	traceId: z.string(),
});

export const apiUnexpectedErrorResponseSchema: z.ZodType<ApiUnexpectedErrorResponse> = z.object({
	type: z.string(),
	title: z.string(),
	errors: z.record(z.string(), z.array(z.string())),
	status: z.number(),
	traceId: z.string(),
});

export const getErrorMessage = (input: unknown): string => {
	const apiErrorResponseParseResult = apiErrorResponseSchema.safeParse(input);

	if (apiErrorResponseParseResult.data) {
		return apiErrorResponseParseResult.data.detail;
	}

	const apiUnexpectedErrorParseResult = apiUnexpectedErrorResponseSchema.safeParse(input);

	if (apiUnexpectedErrorParseResult.data) {
		return Object.entries(apiUnexpectedErrorParseResult.data.errors)
			.map(([key, value]) => `${key}: ${value.join(', ')}`)
			.join(';');
	}

	return String(input);
};
